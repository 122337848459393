import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { AuthContext } from '../context/AuthContext';
import UserList from './UserList';
import AddUser from './AddUser';
// import StatusIndicator from './StatusIndicator';
import styles from './CRM.module.css'; 
import CountdownPopover from './CountdownPopover';

const CRM = () => {
  const { logout, accessToken, setAccessToken } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [pythonVideoConnected] = useState(false);
  const [showOnlyActiveUsers, setShowOnlyActiveUsers] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const socketRef = useRef(null);
  const [emailFilter, setEmailFilter] = useState('');

  const refreshAccessTokenInterval  = 15; //in minutes
  const userRefreshInterval = 5 * 60 * 1000; // 5 minutes in milliseconds
  const [showCountdownPopover, setShowCountdownPopover] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(10); // 10-second countdown
  const logoutTime = 5 * 60 * 1000; // 5 minute for demonstration

  // const inactivityLoggingIntervalId = useRef();
  const logoutTimerId = useRef();
  const countdownIntervalId = useRef();
  const isCountdownActiveRef = useRef(false); // Use ref to track countdown activity

  // Define the start logout countdown function
  const startLogoutCountdown = useCallback(() => {
    isCountdownActiveRef.current = true;
    setShowCountdownPopover(true);
    setSecondsLeft(10); // Initialize countdown

    clearInterval(countdownIntervalId.current); // Clear any existing countdown interval

    countdownIntervalId.current = setInterval(() => {
      setSecondsLeft((prevSeconds) => {
        const newSeconds = prevSeconds - 1;
        if (newSeconds <= 0) {
          clearInterval(countdownIntervalId.current);
          logout(); // Perform logout
          isCountdownActiveRef.current = false; // Reset countdown activity state
          setShowCountdownPopover(false);
        }
        return newSeconds;
      });
    }, 1000); // Decrement every second
  }, [logout]);

  // Use useCallback for resetInactivityTimer
  const resetInactivityTimer = useCallback(() => {
    if (!isCountdownActiveRef.current) {
      clearTimeout(logoutTimerId.current); // Clear any existing timeout
      logoutTimerId.current = setTimeout(startLogoutCountdown, logoutTime);
    }
  }, [startLogoutCountdown, logoutTime]);

  // Define onContinue at the component level
  const onContinue = () => {
    setShowCountdownPopover(false);
    clearTimeout(logoutTimerId.current);
    clearInterval(countdownIntervalId.current);
    isCountdownActiveRef.current = false;
    resetInactivityTimer(); // Reset the inactivity timer
  };

  useEffect(() => {
    // Function to handle mouse movement
    const handleMouseMove = () => {
      resetInactivityTimer();
    };

    // Only add the mousemove listener if the popover is not active
    document.addEventListener('mousemove', handleMouseMove);

    // Initialize the inactivity timer on component mount
    resetInactivityTimer();

    // Cleanup function to clear timers on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(logoutTimerId.current);
      clearInterval(countdownIntervalId.current);
    };
  }, [resetInactivityTimer /* Add any other dependencies here */]);

  // Calculate the number of online users and the total number of users
  const [onlineCount, totalCount] = useMemo(() => {
    const onlineUsers = users.filter(user => user.online).length;
    const totalUsers = users.length;
    return [onlineUsers, totalUsers];
  }, [users]);
  
  // Setup Axios interceptor for handling JWT errors globally
  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && [401, 403].includes(error.response.status)) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Eject the interceptor when the component unmounts to prevent memory leaks
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [logout]);



  // Function to load users from the backend
  const loadUsers = useCallback(async () => {
    console.log("loadUsers called with accessToken:", accessToken);
    try {
      const response = await axios.get('/api/users', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Users loaded:", response.data);
      return response.data; // Return the fetched data for further processing
    } catch (error) {
      console.error('Error fetching users:', error);
      return []; // Return an empty array in case of an error
    }
  }, [accessToken]);

  const mergeOnlineStatus = (fetchedUsers, currentUsers) => {
    const onlineUsersMap = new Map(currentUsers.map(user => [user.email, user.online]));
    const mergedUsers = fetchedUsers.map(user => {
      const onlineStatus = onlineUsersMap.get(user.email);
      return { ...user, online: onlineStatus ?? false };
    });
    return mergedUsers;
  };

  const refreshUsersAndMergeStatus = useCallback(async () => {
    const fetchedUsers = await loadUsers(); // Fetch the latest users
    console.log("Loaduser stats")
    console.log(fetchedUsers)
    console.log("Users stats")
    console.log(users)
    // Assume currentUsersState holds the current state of users including their online status
    const updatedUsers = mergeOnlineStatus(fetchedUsers, users);
    console.log("merged stats")
    console.log(updatedUsers)
    setUsers(currentUsers => mergeOnlineStatus(fetchedUsers, currentUsers)); // Update the state with merged data
  }, [loadUsers]);

  useEffect(() => {
    refreshUsersAndMergeStatus();
  }, [refreshUsersAndMergeStatus]);

  // Function to refresh the access token
  const refreshAccessToken = useCallback(async () => {
    try {
      const response = await axios.post('/api/token', {}, { withCredentials: true });
      setAccessToken(response.data.accessToken);
      console.log(`Getting the accessToken: ${response.data.accessToken}`)
      sessionStorage.setItem('accessToken', response.data.accessToken);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      logout();
    }
  }, [setAccessToken, logout]);



  const initializeSocket = useCallback(() => {
    console.log(`We start socked here`)
    const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:443'; // Adjust with your server URL
    console.log(`ServerUrl is ${serverUrl}`)
    const opts = {
        extraHeaders: {
            'Authorization': `Bearer ${accessToken}`,
            'X-Extension-Signature': 'CRMSystem'
        }
    };
    socketRef.current = io(serverUrl, opts);

    socketRef.current.on('connect', () => {
      console.log('Socket connected');
      socketRef.current.emit('requestFullUserList');
    });

    socketRef.current.on('authError', () => {
      logout();
    });

    socketRef.current.on('userUpdated', (updatedUser) => {
      setUsers(prevUsers => {
        const userIndex = prevUsers.findIndex(user => user.email === updatedUser.email);
        if (userIndex === -1) {
          // User not found, add as new user
          return [...prevUsers, updatedUser];
        } else {
          // User found, update the user information
          return prevUsers.map((user, index) => {
            if (index === userIndex) {
              return { ...user, ...updatedUser }; // Update the user
            }
            return user; // Return other users as is
          });
        }
      });
    });

    socketRef.current.on('pythonVideoStatus', ({ email, connected }) => {
      setUsers(currentUsers => currentUsers.map(user => {
        if (user.email === email) {
          // Update only the specific user's pythonVideoConnected status
          return { ...user, pythonVideoConnected: connected };
        }
        return user;
      }));
    });

    socketRef.current.on('userChange', (data) => {
      if (data.action === 'add') {
        // Log the addition of a new user
        console.log("User ADDED");

        // Add the new user to the state
        setUsers(currentUsers => {
          // Check if the user to be added already exists to prevent duplicates
          const userExists = currentUsers.some(user => user.email === data.user.email);
          if (!userExists) {
            // If the user doesn't exist, add them to the state
            // Presuming the newly added user is initially offline
            
            return [...currentUsers, { ...data.user, online: false }];

          }
          // If the user already exists, return the current state without adding
          return currentUsers;
        });

      } else if (data.action === 'delete') {
        // Log the deletion of a user
        console.log("User Deleted");

        // Remove the deleted user from the state
        setUsers(currentUsers => currentUsers.filter(user => user.email !== data.email));
      }
      // No need to explicitly request the full user list after handling the update
      // as the above logic should adequately update the state
    });

    socketRef.current.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socketRef.current.on('fullUserList', (onlineUsers) => {
      setUsers((currentUsers) => {
        const onlineUsersMap = new Map(onlineUsers.map(user => [user.email, user]));
        console.log("ONLINE USER MAP", onlineUsersMap);

        // Step 1: Update existing users with online status or mark them offline if not present in onlineUsersMap
        const updatedExistingUsers = currentUsers.map(user => {
          const onlineUserInfo = onlineUsersMap.get(user.email);
          if (onlineUserInfo) {
            // Directly use the online status from the onlineUsersMap
            return { ...user, ...onlineUserInfo };
          } else {
            // If a user is not in the online list, consider them offline
            return { ...user, online: false };
          }
        });

        // Step 2: Identify and add any new users from onlineUsers that aren't already in currentUsers
        const newUsers = onlineUsers.filter(onlineUser => 
          !currentUsers.some(currentUser => currentUser.email === onlineUser.email)
        );

        // Combine the updated existing users with any new users
        const mergedUsers = [...updatedExistingUsers, ...newUsers];
        console.log("MERGED USERS", mergedUsers);

        return mergedUsers;
      });
    });


    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [accessToken, logout]);
  // }, [accessToken, setPythonVideoConnected]);

  useEffect(() => {
    console.log(`We are in useeffect and calling now loadusers`)
    loadUsers();
    initializeSocket();

    console.log('Python Video Connected status changed:', pythonVideoConnected);

    // Refresh token every 14 minutes
    const tokenRefreshInterval = setInterval(refreshAccessToken, refreshAccessTokenInterval * 60 * 1000);

    // Refresh user list every 5 minutes to ensure data remains fresh
    const usersRefreshInterval = setInterval(loadUsers, userRefreshInterval);

    return () => {
      clearInterval(tokenRefreshInterval);
      clearInterval(usersRefreshInterval);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [loadUsers, pythonVideoConnected, refreshAccessToken, initializeSocket, userRefreshInterval, refreshAccessTokenInterval, accessToken]);


  // Calculate the total number of devices logged in
  const totalDevicesLoggedIn = useMemo(() => {
    return users.reduce((total, user) => total + (user.devices?.length ?? 0), 0);
  }, [users]);


  return (
    <div className={styles.crmContainer}>
      <aside className={styles.sidebar}>
        <button onClick={logout} className={styles.sidebarButton}>Logout</button>
        <button onClick={() => setShowAddUser(false)} className={styles.sidebarButton}>User List</button>
        <button onClick={() => setShowAddUser(true)} className={styles.sidebarButton}>Add User</button>
      </aside>
      <main className={styles.mainContent}>
        {/* Conditionally render the online users counter and devices logged in counter only when showing the User List */}
        {!showAddUser && (
          <>
            <h2 className={styles.userCounter}>Users Online: {onlineCount} / {totalCount}</h2>
            {/* Adding the Devices Logged In counter right under the Users Online counter */}
            <h2 className={styles.userCounter}>Devices Logged In: {totalDevicesLoggedIn}</h2>
            <button onClick={() => setShowOnlyActiveUsers(!showOnlyActiveUsers)} className={styles.toggleButton}>
              {showOnlyActiveUsers ? 'Show All Users' : 'Show Only Active Users'}
            </button>
            <div className={styles.filterContainer}>
              <input
                type="text"
                placeholder="Filter by email"
                value={emailFilter}
                onChange={(e) => setEmailFilter(e.target.value)}
                maxLength={50}
                className={styles.filterInput}
              />
            </div>
          </>
        )}
        {showAddUser ? (
          <AddUser onUserAdded={() => {
            // socketRef.current.emit('requestFullUserList');
            setShowAddUser(false);
            //ToDo: Here is one error the last page when adding user is not properly loading users
            // info is mixes no email is given so a merge of newly added and existing data, thus forced
            // reload works for now
            window.location.reload();
          }} />
        ) : (
          <UserList 
            emailFilter={emailFilter}
            pythonVideoConnected={pythonVideoConnected}
            refreshUsersAndMergeStatus={refreshUsersAndMergeStatus}
            setUsers={setUsers}
            showOnlyActiveUsers={showOnlyActiveUsers}
            socketRef={socketRef}
            users={users}
          />
        )}
      </main>
      {showCountdownPopover && (
        <CountdownPopover
          secondsLeft={secondsLeft}
          onContinue={onContinue}
          onLogout={logout}
        />
      )}
    </div>
  );
};

export default CRM;