import React, { createContext, useState, useEffect, useCallback } from 'react'; // Import useCallback
import axios from 'axios';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken'));
  const RefreshAccessTokenTime = 15; // in minutes

  // Wrap refreshAccessToken with useCallback
  const refreshAccessToken = useCallback(async () => {
    try {
      // Use withCredentials to include the refresh token cookie in the request
      const response = await axios.post('/api/token', {}, { withCredentials: true });
      setAccessToken(response.data.accessToken);
      console.log(`Received new Access Token ${response.data.accessToken}`);
      sessionStorage.setItem('accessToken', response.data.accessToken);
    } catch (error) {
      console.error('Error refreshing access token:', error);
      logout(); // If refresh fails, log out the user
    }
  }, []); // Add any dependencies here. If refreshAccessToken depends on props or state, list them here.

  useEffect(() => {
    // Attempt to refresh the access token periodically
    const interval = setInterval(() => {
      if (accessToken) {
        refreshAccessToken();
      }
    }, RefreshAccessTokenTime * 60 * 1000); // Refresh every x minutes

    return () => clearInterval(interval);
  }, [accessToken, refreshAccessToken]); // Add refreshAccessToken as a dependency

  const logout = async () => {
    try {
      await axios.post('/api/logout', {}, { withCredentials: true });
    } catch (error) {
      console.error('Logout error:', error);
    }
    sessionStorage.removeItem('accessToken');
    setAccessToken(null);
  };

  const contextValue = {
    accessToken,
    setAccessToken,
    logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};