import React from 'react';
import styles from './StatusIndicator.module.css'; // Ensure the path is correct

const StatusIndicator = ({ isConnected, color, label }) => {
  const ledClass = `led-${color}`;

  return (
    <div className={styles.indicatorContainer}>
      {/* Use bracket notation to access the property on the styles object */}
      <span className={`${styles.led} ${styles[ledClass]}`}></span>
      {label}
    </div>
  );
};

export default StatusIndicator;