import React, { useEffect, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as necessary
import StatusIndicator from './StatusIndicator';
import styles from './UserList.module.css';
import DynamicModal from './DynamicModal';
import EditWebpagesModal from './EditWebpagesModal';

const UserList = React.memo(({
  emailFilter,
  pythonVideoConnected,
  setUsers,
  showOnlyActiveUsers,
  socketRef,
  users
}) => {
  console.log("Users prop in UserList:", users);
  const { accessToken } = useContext(AuthContext); // Access the accessToken from AuthContext
  const [showOptions, setShowOptions] = useState(null); // Track which user's options are shown
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [editWebpagesUser, setEditWebpagesUser] = useState(null); // Holds the user whose webpages are being edited
  const [showEditWebpagesModal, setShowEditWebpagesModal] = useState(false); // Whether the edit modal is shown
  const MAX_VISIBLE_PAGES = 6;

  // Adjusted filtering logic to include emailFilter and showOnlyActiveUsers
  const filteredUsers = useMemo(() => users.filter(user => {
    const matchesEmailFilter = emailFilter.trim() === '' || user.email.toLowerCase().includes(emailFilter.trim().toLowerCase());
    return (!showOnlyActiveUsers || user.online) && matchesEmailFilter;
  }), [users, showOnlyActiveUsers, emailFilter]);



  // Adjust totalPages calculation to account for filtered users
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleUsersPerPageChange = (event) => {
    setUsersPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1); // Reset to the first page when changing the number of users per page
  };

  const handleDelete = async () => {
    // Immediately close the confirmation modal and reset message
    console.log("handleDelete function called");
    setShowConfirmation(false); 
    setModalMessage('');
    // Check if the user being deleted is an admin and if at least one other admin remains
    const userIsAdmin = users.some(user => user.email === emailToDelete && user.role === 'admin');
    if (userIsAdmin) {
      const remainingAdmins = users.filter(user => user.email !== emailToDelete && user.role === 'admin').length;
      if (remainingAdmins === 0) {
        setModalMessage('Deletion Error: At least one admin must remain.');
        setShowConfirmation(true);
        setTimeout(() => setShowConfirmation(false), 3000); // Auto-close the modal with the error message after 3 seconds
        return;
      }
    }

    // Proceed with deletion if the check passes
    try {
      const response = await axios.delete('/api/delete-user', {
        headers: { Authorization: `Bearer ${accessToken}` },
        data: { email: emailToDelete }
      });

      if (response.status === 200) {
        setModalMessage('User deleted successfully.');
      } else {
        // Handle unexpected response status
        setModalMessage('Failed to delete user. Unexpected response.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      setModalMessage('Failed to delete user. ' + (error.response?.data?.message || 'Please try again later.'));
    }

    // After setting the message, show the modal to display it.
    setShowConfirmation(true);

    // Instead of auto-closing, allow the user to close the modal manually after reading the message.
    // If auto-close is desired, uncomment the following line:
    setTimeout(() => setShowConfirmation(false), 3000);
  };

  useEffect(() => {
    // console.log(`Current Page: ${currentPage}, Total Pages: ${totalPages}, Filtered Users Length: ${filteredUsers.length}`);
    const newTotalPages = Math.ceil(filteredUsers.length / usersPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(newTotalPages);
    } else if (currentPage < 1 && newTotalPages > 0) {
      setCurrentPage(1);
    }
  }, [filteredUsers.length, usersPerPage, currentPage,totalPages]);

  // useEffect(() => {
    // const startIndex = (currentPage - 1) * usersPerPage;
    // const endIndex = startIndex + usersPerPage;
    // console.log(`Rendering users from index ${startIndex} to ${endIndex}:`, filteredUsers.slice(startIndex, endIndex));
  // }, [currentPage, usersPerPage, filteredUsers]);

  const getPythonVideoColor = (isConnected) => {
    // console.log(`Is python for that user connected or not? ${isConnected}`)
    return isConnected ? 'green' : 'red';
  };

  const getDeviceConnectionColor = (devices) => {
    const nonPythonDevices = devices.filter(device => device.deviceType !== 'python');
    const hasConnectedAndroid = nonPythonDevices.some(device => device.deviceType === 'android' && device.connected);
    const hasConnectedIOS = nonPythonDevices.some(device => device.deviceType === 'ios' && device.connected);
    let color;

    if (hasConnectedAndroid && hasConnectedIOS) color = 'purple';
    else if (hasConnectedAndroid) color = 'green';
    else if (hasConnectedIOS) color = 'blue';
    else if (nonPythonDevices.length === 0 || nonPythonDevices.every(device => !device.connected)) color = 'red';
    else color = 'grey';

    return color;
  };

  const onSaveEditWebpages = async (email, webpages) => {
    try {
      const response = await axios.put('/api/update-allowed-webpages', {
        email: email,
        allowedWebpages: webpages,
      }, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (response.status === 200) {
        setUsers(currentUsers => currentUsers.map(user => {
          if (user.email === email) {
            return { ...user, allowedWebpages: webpages };
          }
          return user;
        }));
        console.log("User's allowed webpages updated successfully");
      } else {
        throw new Error('Unexpected response status: ' + response.status);
      }
    } catch (error) {
      console.error('Error updating allowed webpages:', error);
      setModalMessage('Failed to update allowed webpages. Please try again.');
      // Assuming you have a mechanism to show this message to the user
    }
  };

  const renderPagination = () => {
      let pages = [];
      let startPage = Math.max(currentPage - 2, 1);
      let endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages);

      // Always render "First" button but disable and style it conditionally
      pages.push(
          <button
              key="first"
              onClick={() => handlePageClick(1)}
              disabled={currentPage === 1}
              style={{
                  ...buttonStyle,
                  backgroundColor: currentPage === 1 ? 'grey' : 'white',
                  color: currentPage === 1 ? 'darkgrey' : 'black', // Text color for disabled state
                  cursor: currentPage === 1 ? 'default' : 'pointer',
              }}
          >
              First
          </button>
      );

      if (startPage > 1) {
          pages.push(<span key="firstEllipsis" style={ellipsisStyle}>...</span>);
      }

      for (let i = startPage; i <= endPage; i++) {
          pages.push(
              <button
                  key={i}
                  style={{
                      ...buttonStyle,
                      backgroundColor: currentPage === i ? 'grey' : 'white',
                      color: currentPage === i ? 'white' : 'black',
                  }}
                  onClick={() => handlePageClick(i)}
                  disabled={currentPage === i}
              >
                  {i}
              </button>
          );
      }

      if (endPage < totalPages) {
          pages.push(<span key="lastEllipsis" style={ellipsisStyle}>...</span>);
      }

      // Always render "Last" button but disable and style it conditionally
      pages.push(
          <button
              key="last"
              onClick={() => handlePageClick(totalPages)}
              disabled={currentPage === totalPages}
              style={{
                  ...buttonStyle,
                  backgroundColor: currentPage === totalPages ? 'grey' : 'white',
                  color: currentPage === totalPages ? 'darkgrey' : 'black', // Text color for disabled state
                  cursor: currentPage === totalPages ? 'default' : 'pointer',
              }}
          >
              Last
          </button>
      );

      return (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
              {pages}
          </div>
      );
  };

  const buttonStyle = {
      margin: '0 5px',
      border: '1px solid black',
      opacity: '1',
  };

  const ellipsisStyle = {
      margin: '0 5px',
  };

  const currentUsers = useMemo(() => {
    const startIndex = (currentPage - 1) * usersPerPage;
    const endIndex = startIndex + usersPerPage;
    return filteredUsers.slice(startIndex, Math.min(endIndex, filteredUsers.length));
  }, [currentPage, usersPerPage, filteredUsers]);

  return (
    <div>
      <h2>User Management</h2>
      <DynamicModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDelete}
        message={modalMessage || `Do you really want to delete ${emailToDelete}? All data will be deleted!`}
        confirmMode={true}
        emailToDelete={emailToDelete}
      />
      <div style={{ marginBottom: '15px' }}>
        Show on this page: 
        <select value={usersPerPage} onChange={handleUsersPerPageChange}>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
      </div>
      <table className={styles.userTable}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Username</th>
            <th>Role</th>
            <th>Allowed Webpages</th>
            <th>Device Number</th>
            <th>Device ID</th>
            <th>Device Type</th>
            <th>Version</th>
            <th>API Level</th>
            <th>Device Model</th>
            <th>Python Video Status</th>
            <th>Device Connection Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user, index) => (
            user.devices.length === 0 ? (
              <tr key={`${user.email}-no-devices`}>
                <td>{user.email}</td>
                <td>{user.username}</td>
                <td>{user.role}</td>
                <td>
                  <div className={styles.verticalScrollContainer}>
                    {user.allowedWebpages && user.allowedWebpages.length > 0
                      ? user.allowedWebpages.map((webpage, index) => (
                          <div key={index} className={styles.horizontalScrollItem}>
                            {webpage.replace(/^(https?:\/\/)(\*\.|www\.)?/, '')}
                          </div>
                        ))
                      : "N/A"}
                  </div>
                </td>
                <td>0</td> {/* Adjusted to show 0 devices if there are none */}
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>
                  <StatusIndicator isConnected={false} color="red" label="Python Video" />
                </td>
                <td>
                  <StatusIndicator isConnected={false} color="red" label="Device" />
                </td>
                <td style={{cursor: 'pointer', position: 'relative'}}>
                  <div onClick={() => setShowOptions(showOptions === user.email ? null : user.email)}>⋮</div>
                  {showOptions === user.email && (
                    <ul style={{position: 'absolute', listStyle: 'none', margin: 0, padding: '5px', backgroundColor: 'white', boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', zIndex: 100}}>
                        <li style={{padding: '5px', cursor: 'pointer'}} onClick={(e) => { 
                        e.stopPropagation();
                        setEditWebpagesUser(user); // Set the user whose webpages will be edited
                        setShowEditWebpagesModal(true); 
                        setShowOptions(null); 
                      }}>Edit Webpages</li>
                      <li style={{padding: '5px', cursor: 'pointer'}} onClick={(e) => { 
                        e.stopPropagation();
                        setEmailToDelete(user.email); 
                        setShowConfirmation(true); 
                        setShowOptions(null);
                      }}>Delete User</li>
                      <li style={{padding: '5px', cursor: 'pointer'}} onClick={(e) => {
                        e.stopPropagation();
                        console.log("Block user clicked for", user.email);
                        setShowOptions(null);
                      }}>Block User</li>
                    </ul>
                  )}
                </td>
              </tr>
            ) : (
              user.devices.map((device, deviceIndex) => (
                <tr key={`${user.email}-${device.deviceId}`}>
                  {deviceIndex === 0 ? (
                    <>
                      <td rowSpan={user.devices.length}>{user.email}</td>
                      <td rowSpan={user.devices.length}>{user.username}</td>
                      <td rowSpan={user.devices.length}>{user.role}</td>
                      <td rowSpan={user.devices.length}>
                        <div className={styles.verticalScrollContainer}>
                          {user.allowedWebpages && user.allowedWebpages.length > 0
                            ? user.allowedWebpages.map((webpage, wpIndex) => (
                                <div key={wpIndex} className={styles.horizontalScrollItem}>
                                  {webpage.replace(/^(https?:\/\/)(\*\.|www\.)?/, '')}
                                </div>
                              ))
                            : "N/A"}
                        </div>
                      </td>
                    </>
                  ) : null}
                  <td>{deviceIndex + 1}</td>
                  <td>{device.deviceId}</td>
                  <td>{device.deviceType}</td>
                  <td>{device.version}</td>
                  <td>{device.apiLevel}</td>
                  <td>{device.deviceModel}</td>
                  {deviceIndex === 0 ? (
                    <>
                      <td rowSpan={user.devices.length}>
                        <StatusIndicator isConnected={user.pythonVideoConnected} color={getPythonVideoColor(user.pythonVideoConnected)} label="Python Video" />
                      </td>
                      <td rowSpan={user.devices.length}>
                        <StatusIndicator isConnected={user.devices.some(device => device.connected)} color={getDeviceConnectionColor(user.devices)} label="Device" />
                      </td>
                      <td rowSpan={user.devices.length} style={{cursor: 'pointer', position: 'relative'}}>
                        <div onClick={() => setShowOptions(showOptions === user.email ? null : user.email)}>⋮</div>
                        {showOptions === user.email && (
                          <ul style={{position: 'absolute', listStyle: 'none', margin: 0, padding: '5px', backgroundColor: 'white', boxShadow: '0px 2px 5px rgba(0,0,0,0.2)', zIndex: 100}}>
                            <li style={{padding: '5px', cursor: 'pointer'}} onClick={(e) => { 
                              e.stopPropagation();
                              setEditWebpagesUser(user); // Set the user whose webpages will be edited
                              setShowEditWebpagesModal(true); 
                              setShowOptions(null); 
                            }}>Edit Webpages</li>
                            <li style={{padding: '5px', cursor: 'pointer'}} onClick={(e) => { 
                              e.stopPropagation();
                              setEmailToDelete(user.email); 
                              setShowConfirmation(true); 
                              setShowOptions(null);
                            }}>Delete User</li>
                            <li style={{padding: '5px', cursor: 'pointer'}} onClick={(e) => {
                              e.stopPropagation();
                              console.log("Block user clicked for", user.email);
                              setShowOptions(null);
                            }}>Block User</li>
                          </ul>
                        )}
                      </td>
                    </>
                  ) : null}
                </tr>
              ))
            )
          ))}
        </tbody>
      </table>
      {renderPagination()}
      <EditWebpagesModal
        isOpen={showEditWebpagesModal}
        onClose={() => setShowEditWebpagesModal(false)}
        onSave={onSaveEditWebpages}
        initialWebpages={editWebpagesUser ? editWebpagesUser.allowedWebpages : []}
        userEmail={editWebpagesUser ? editWebpagesUser.email : ''}
      />
    </div>
  );
});

export default UserList;