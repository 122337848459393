//NotFound404.js
import React from 'react';
// import { Link } from 'react-router-dom';
import './ErrorPageStyles.module.css'; // Assume this file contains the shared styles

const NotFound404 = () => {
  return (
    <div className="error-container">
      <h2>404 Not Found</h2>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
    </div>
  );
};

export default NotFound404;