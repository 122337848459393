import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate instead of useHistory
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import styles from './LoginForm.module.css'; 

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const { setAccessToken } = useContext(AuthContext); 

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Login Attempt: ", email);

    try {
      const response = await axios.post('/api/login', {
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-requested-with': 'XMLHttpRequest'
        }
      });


      console.log("Response received from server: ", response);
      if (response.data.accessToken) {
        // Assuming your server responds with an access token
        sessionStorage.setItem('accessToken', response.data.accessToken);
        console.log('Access token stored in sessionStorage:', response.data.accessToken);
        setAccessToken(response.data.accessToken); // Update the context with the new access token
        navigate('/crm');
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error Response:", error.response.data);
        setMessage(error.response.data.message || "An error occurred during login.");
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error Request:", error.request);
        setMessage("The server did not respond to the login request.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error Message:', error.message);
        setMessage("An error occurred during login.");
      }
    }
  };

  return (
    <div className={styles.loginContainer}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="text"
          id="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.input}
        />
        <input
          type="password"
          id="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>Login</button>
      </form>
      <div className={styles.message}>{message}</div>
      <Link to="/forgot-password" className={styles.link}>Forgot Password</Link>
    </div>
  );
};

export default LoginForm;