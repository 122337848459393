import React, { useRef } from 'react';
import styles from './CountdownPopover.module.css'; // Adjust the path as needed

const CountdownPopover = ({ secondsLeft, onContinue, onLogout }) => {
  const maxTimeRef = useRef(secondsLeft);
  const radius = 50; // Adjust based on SVG container size
  const circumference = 2 * Math.PI * (radius - 5); // Adjust stroke-width

  // Calculate the stroke-dashoffset based on secondsLeft
  const strokeDashoffset = ((maxTimeRef.current - secondsLeft) / maxTimeRef.current) * circumference;

  return (
    <div className={styles.popoverOverlay}>
      <div className={styles.popover}>
        <div className={styles.svgContainer}>
          <svg width="120" height="120"> {/* Adjust based on desired size */}
            <circle
              className={styles.circle}
              cx="60"
              cy="60"
              r={radius - 5}
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
            />
          </svg>
          <div className={styles.countdownCircle}>
            {secondsLeft}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={onContinue} className={styles.continueButton}>OK</button>
          <button onClick={onLogout} className={styles.logoutButton}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default CountdownPopover;