// src/components/AddUser.js
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import styles from './CRM.module.css';
import DynamicModal from './DynamicModal';

const AddUser = ({ onUserAdded }) => {
    const { accessToken } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');
    const [businessType, setBusinessType] = useState('B2C');

    const [allowedWebpages, setAllowedWebpages] = useState([]);
    const [webpageInput, setWebpageInput] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmMode, setIsConfirmMode] = useState(false);
    const [shouldTriggerOnUserAdded, setShouldTriggerOnUserAdded] = useState(false);

    useEffect(() => {
        // Check if the modal is not open and it's time to trigger onUserAdded
        if (!isModalOpen && shouldTriggerOnUserAdded) {
            onUserAdded();
            setShouldTriggerOnUserAdded(false); // Reset the trigger
        }
    }, [isModalOpen, shouldTriggerOnUserAdded, onUserAdded]);


    // Handle adding webpages
    const handleAddWebpage = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter

            let formattedInput = webpageInput.trim();

            // Ensure all URLs start with https://
            if (!formattedInput.startsWith('https://')) {
                if (formattedInput.startsWith('http://')) {
                    formattedInput = 'https://' + formattedInput.substring(7);
                } else {
                    formattedInput = 'https://' + formattedInput;
                }
            }

            // Correct common prefixes (w., ww., wwww.) to www. for URLs starting with https://
            if (formattedInput.match(/^https:\/\/w{1,4}\./)) {
                formattedInput = formattedInput.replace(/^https:\/\/w{1,4}\./, 'https://www.');
            } else if (!formattedInput.includes("www.")) {
                // Add www. if it's not a subdomain or already starts with www.
                const domainParts = formattedInput.split('/');
                if (!domainParts[2].startsWith('www.') && !domainParts[2].includes('.', domainParts[2].indexOf('.') + 1)) {
                    domainParts[2] = 'www.' + domainParts[2];
                    formattedInput = domainParts.join('/');
                }
            }

            // Check if the URL is already in the list
            if (allowedWebpages.includes(formattedInput)) {
                alert('Already in the list.');
                return; // Exit early
            }

            // General URL validation regex, allowing for a wildcard '*' as TLD or at least two letters
            // Updated to accommodate wildcard subdomains and TLDs
            const urlRegex = /^(https:\/\/)(www\.|(\*\.)?)([\w-]+\.)*([\w-]+\.)+([a-z]{2,}|\*)$/;

            // Check if the URL is valid
            if (urlRegex.test(formattedInput)) {
                // If valid, add the webpage
                setAllowedWebpages([...allowedWebpages, formattedInput]);
                setWebpageInput(''); // Clear input after adding
            } else {
                // If not valid, alert the user
                alert('Please enter a valid URL. For wildcard domains, use "*", otherwise use at least two letters for the domain.');
            }
        }
    };

    // // Handle removing webpages
    // const handleRemoveWebpage = (index) => {
    //     setAllowedWebpages(allowedWebpages.filter((_, i) => i !== index));
    // };

    const handleAddUser = async (e) => {
        e.preventDefault();

        // Sanitize inputs
        const sanitizedEmail = email.trim();
        const sanitizedUsername = username.trim().substring(0, 50); // Limit username to 50 characters
        let sanitizedPassword = password.trim(); // Trim spaces from password

        // Enhanced email validation
        const atPosition = sanitizedEmail.indexOf('@');
        const dotPosition = sanitizedEmail.lastIndexOf('.');
        if (atPosition < 1 || dotPosition < atPosition + 2 || dotPosition + 2 >= sanitizedEmail.length) {
            console.error('Invalid email format.');
            alert('Invalid email format. Please enter a valid email address.');
            return; // Exit early if email validation fails
        }

        // Check for password length after trimming spaces
        if (sanitizedPassword.length < 1) {
            // Show confirmation dialog when no password is given
            const generatePassword = window.confirm("No password is given. One will be generated. Click 'OK' to proceed or 'Cancel' to enter a password.");
            if (!generatePassword) {
                return; // User chose to cancel, exit early to allow them to enter a password
            }
            sanitizedPassword = ''; // Proceed with no password, backend will generate one
        } else if (sanitizedPassword.length < 8 || sanitizedPassword.length > 100) {
            console.error('Password must be at least 8 characters and no more than 100 characters.');
            alert('Password must be at least 8 characters and no more than 100 characters.');
            return; // Exit the function early if validation fails
        }

        try {
            const response = await axios.post('/api/add-user', { 
                email: sanitizedEmail, 
                username: sanitizedUsername, 
                password: sanitizedPassword, 
                role, 
                allowedWebpages
            }, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            // Assuming the server sends a success message
            setModalMessage(response.data.message || 'User added successfully.');
            setIsModalOpen(true);
            setIsConfirmMode(false); // Set to notification mode
            setShouldTriggerOnUserAdded(true); // Handle post-addition logic, like refreshing the user list
        } catch (error) {
            if (error.response && error.response.status === 409) {
                // User exists, prompt for overwrite
                setModalMessage(`User ${sanitizedEmail} already exists. Do you want to overwrite?`);
                setIsModalOpen(true);
                setIsConfirmMode(true); // Set to confirmation mode
            } else {
                // Other errors
                setModalMessage(error.response?.data?.message || 'Error adding user. Please try again.');
                setIsModalOpen(true);
                setIsConfirmMode(false); // Set to notification mode, as we're just informing the user of the error
            }
        }
    };

    const handleOverwriteConfirm = async () => {

        // Preparing the request payload
        const payload = {
            email: email.trim(),
            username: username.trim().substring(0, 50),
            password: password.trim(),
            role,
            overwrite: true,
            allowedWebpages
        };


        try {
            const response = await axios.post('/api/add-user', payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            // Handle success for overwrite
            setModalMessage(response.data.message || 'User updated successfully.');
            setIsConfirmMode(false); // Set to notification mode
            setIsModalOpen(true);
            setShouldTriggerOnUserAdded(true); // Indicate that the user addition/updation process is complete
        } catch (error) {
            console.error("Error in 'Add User' request:", error);

            // Handle errors for overwrite attempt
            // Checking if the error response is available, otherwise logging the entire error
            if (error.response) {
                setModalMessage(error.response.data.message || 'Error updating user. Please try again.');
            } else {
                setModalMessage('Error updating user. Please try again.');
            }

            setIsConfirmMode(false); // Set to notification mode
            setIsModalOpen(true);
        }
    };

return (
    <section className={styles.formContainer}>
      <h2>Add New User</h2>
      <form onSubmit={handleAddUser} className="addUserForm">
        <input className={styles.formInput} type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required/>
        <input className={styles.formInput} type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" required/>
        <input className={styles.formInput} type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        <select className={styles.formSelect} value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </select>
          {/* B2B/B2C Dropdown */}
          <select className={styles.formSelect} value={businessType} onChange={(e) => setBusinessType(e.target.value)}>
            <option value="B2C">B2C</option>
            <option value="B2B">B2B</option>
          </select>
          {/* Conditional rendering for B2B */}
          {businessType === 'B2B' && (
            <>
              <p className={styles.instructionText}>Add allowed webpage:</p> {/* Instruction text */}
              <input
                className={`${styles.formInput} ${styles.slideEffect}`}
                type="text"
                value={webpageInput}
                onChange={(e) => setWebpageInput(e.target.value)}
                onKeyPress={handleAddWebpage}
                placeholder="Type and press Enter"
              />
                <div className={styles.webpageList}>
                  {allowedWebpages.map((webpage, index) => (
                    <div key={index} className={styles.webpageTag}>
                      <span className={styles.webpageText}>{webpage}</span>
                      <button type="button" onClick={() => setAllowedWebpages(allowedWebpages.filter((_, i) => i !== index))}>
                        x
                      </button>
                    </div>
                  ))}
                </div>
            </>
          )}
          <button className={styles.formButton} type="submit">Add New User</button>
        </form>
        <DynamicModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleOverwriteConfirm}
          message={modalMessage}
          confirmMode={isConfirmMode}
        />
    </section>
);
};

export default AddUser;