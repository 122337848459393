import React, { useEffect } from 'react';
import styles from './ConfirmationModal.module.css';

const DynamicModal = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  confirmMode = false, // Determines if it's a confirmation or notification modal
  emailToDelete = '', // Optional: used in the message when deleting a user
}) => {
  useEffect(() => {
    if (!confirmMode && isOpen) {
      const timer = setTimeout(() => onClose(), 3000); // Auto-close after 3 seconds for notifications
      return () => clearTimeout(timer);
    }
  }, [confirmMode, isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <p dangerouslySetInnerHTML={{ __html: message.replace('{email}', `<strong>${emailToDelete}</strong>`) }}></p>
        {confirmMode ? (
          <>
            <button onClick={onConfirm} className={styles.button}>Yes</button>
            <button onClick={onClose} className={styles.button}>No</button>
          </>
        ) : (
          <button onClick={onClose} className={styles.button}>OK</button>
        )}
      </div>
    </div>
  );
};

export default DynamicModal;