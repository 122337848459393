import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import styles from './AuthFormStyles.module.css';

// TODO: as we need to change many react things to eb able to uupdate to the latest eye slashed eye icons library we have them as svg for now

const eyeIcon = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 4.5C7.30558 4.5 3.31985 7.328 1.5 12C3.31985 16.672 7.30558 19.5 12 19.5C16.6944 19.5 20.6801 16.672 22.5 12C20.6801 7.328 16.6944 4.5 12 4.5ZM12 17.5C8.41015 17.5 5.5 14.5899 5.5 11C5.5 7.41015 8.41015 4.5 12 4.5C15.5899 4.5 18.5 7.41015 18.5 11C18.5 14.5899 15.5899 17.5 12 17.5Z" fill="currentColor"/>
    <circle cx="12" cy="11" r="3" fill="currentColor"/>
  </svg>
);

const eyeSlashIcon = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 2L22 22M12 4.5C7.30558 4.5 3.31985 7.328 1.5 12C2.17857 13.6486 3.20601 15.1087 4.5 16.25M12 19.5C16.6944 19.5 20.6801 16.672 22.5 12C21.8214 10.3514 20.794 8.89127 19.5 7.75M15.75 15.75C14.8913 16.6087 13.5 17.5 12 17.5C8.41015 17.5 5.5 14.5899 5.5 11C5.5 9.5 6.39127 8.10873 7.25 7.25M12 4.5C15.5899 4.5 18.5 7.41015 18.5 11C18.5 12.5 17.6087 13.8913 16.75 14.75" stroke="currentColor" strokeWidth="2"/>
  </svg>
);

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (token) {
      axios.get(`/api/check-token?token=${token}`)
        .then(response => {
          setIsTokenValid(true);
        })
        .catch(error => {
          console.error('Error validating token:', error);
          setMessage('Your token has expired or is invalid. Please request a new password reset.');
          setIsTokenValid(false);
        });
    }
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isTokenValid) {
      setMessage('Token is missing or invalid.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    if (newPassword.length < 8 || newPassword.length > 256) {
      setMessage('Password must be between 8 and 256 characters.');
      return;
    }
    try {
      const response = await axios.post('/api/reset-password', { token, newPassword });
      setMessage(response.data.message || 'Password has been reset successfully');
      if (response.data.message === 'Password has been reset successfully') {
        setResetSuccessful(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage((error.response && error.response.data && error.response.data.message) || 'An error occurred while resetting the password.');
    }
  };

  const togglePasswordVisibility = (setShowPassword) => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <div className={styles.authContainer}>
      <h2>Reset Password</h2>
      {isTokenValid && !resetSuccessful && (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.passwordGroup}>
            <input
              id="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              className={styles.input}
              required
            />
            <div className={styles.eyeContainer} onClick={() => togglePasswordVisibility(setShowNewPassword)}>
              {showNewPassword ? eyeSlashIcon : eyeIcon}
            </div>
          </div>
          <div className={styles.passwordGroup}>
            <input
              id="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className={styles.input}
              required
            />
            <div className={styles.eyeContainer} onClick={() => togglePasswordVisibility(setShowConfirmPassword)}>
              {showConfirmPassword ? eyeSlashIcon : eyeIcon}
            </div>
          </div>
          <button type="submit" className={styles.button}>
            Reset Password
          </button>
        </form>
      )}
      {resetSuccessful && (
        <>
          <p>{message}</p>
          <p>You can close this window now.</p>
        </>
      )}
      {!resetSuccessful && message && <div className={styles.message}>{message}</div>}
    </div>
  );
};

export default ResetPassword;