//InternalServerError500.js
import React from 'react';
// import { Link } from 'react-router-dom';
import './ErrorPageStyles.module.css'; // Reuse the same styles as the 404 component

const InternalServerError500 = () => {
  return (
    <div className="error-container">
      <h2>500 Internal Server Error</h2>
      <p>Sorry, something went wrong on our end. Please try again later.</p>
    </div>
  );
};

export default InternalServerError500;