// EditWebpagesModal.js
import React, { useState, useEffect } from 'react';
import styles from './ConfirmationModal.module.css'; // Adjust the path as needed

const EditWebpagesModal = ({ isOpen, onClose, onSave, initialWebpages, userEmail }) => {
  const [webpages, setWebpages] = useState(initialWebpages);
  const [newWebpage, setNewWebpage] = useState('');

  useEffect(() => {
    setWebpages(initialWebpages);
  }, [initialWebpages]);

  const formatAndValidateWebpage = (input) => {
      let formattedInput = input.trim();

      // Ensure all URLs start with https://
      if (!formattedInput.startsWith('https://')) {
          if (formattedInput.startsWith('http://')) {
              formattedInput = 'https://' + formattedInput.substring(7);
          } else {
              formattedInput = 'https://' + formattedInput;
          }
      }

      // Handle wildcard subdomains specifically
      if (!formattedInput.match(/^https:\/\/\*\.[\w-]+\.[\w-]+/)) {
          // Correct common prefixes (w., ww., wwww.) to www. for non-wildcard URLs
          if (formattedInput.match(/^https:\/\/w{1,4}\./)) {
              formattedInput = formattedInput.replace(/^https:\/\/w{1,4}\./, 'https://www.');
          } else if (!formattedInput.includes("www.")) {
              // For other cases, add www. if it's a simple domain without subdomains
              let urlParts = formattedInput.split('/');
              let domainParts = urlParts[2].split('.');

              if (domainParts.length === 2 && !formattedInput.match(/^https:\/\/www\./)) {
                  urlParts[2] = 'www.' + urlParts[2];
                  formattedInput = urlParts.join('/');
              }
          }
      }

      // Update the URL validation regex to properly allow for wildcard subdomains and TLDs
      // This regex checks for either a standard URL with www., a wildcard subdomain, or a regular domain
      const urlRegex = /^(https:\/\/)((www\.)|(\*\.)?)([\w-]+\.)*([\w-]+\.)+([a-z]{2,}|\*)$/;

      if (urlRegex.test(formattedInput)) {
          return formattedInput; // Return formatted and validated URL
      } else {
          return null; // Return null if URL is invalid
      }
  };

  const handleAddWebpage = () => {
    const formattedInput = formatAndValidateWebpage(newWebpage);
    if (formattedInput) {
      if (!webpages.includes(formattedInput)) {
        setWebpages([...webpages, formattedInput]);
        setNewWebpage(''); // Clear the input after adding
      } else {
        alert('Already in the list.');
      }
    } else {
      alert('Please enter a valid URL. For wildcard domains, use "*", otherwise use at least two letters for the domain.');
    }
  };

  const handleDeleteWebpage = (index) => {
    setWebpages(webpages.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    onSave(userEmail, webpages);
    onClose(); // Close the modal after saving
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h3>Edit Allowed Webpages for {userEmail}</h3>
        <div>
          {webpages.map((webpage, index) => (
            <div key={index} className={styles.webpageListItem}>
              <span className={styles.webpageText}>{webpage}</span>
              <button onClick={() => handleDeleteWebpage(index)} className={styles.deleteButton}>X</button>
            </div>
          ))}
          <p className={styles.inputLabel}>Add allowed wepages:</p> {/* Instruction text */}
          <input 
            type="text"
            value={newWebpage}
            onChange={(e) => setNewWebpage(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleAddWebpage()}
            placeholder="Type and press Enter" /* Updated placeholder text */
            className={styles.inputField}
          />
        </div>
        <div className={styles.actionButtons}>
          <button onClick={handleSave} className={styles.saveButton}>Save</button>
          <button onClick={onClose} className={styles.cancelButton}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EditWebpagesModal;