// src/components/ForgotPassword.js or similar
import React, { useState } from 'react';
import axios from 'axios';
import styles from './AuthFormStyles.module.css';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(false); // New state to track if the message is an error

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/forgot-password', { email });
      setMessage(response.data.message || 'Reset link sent, if you are in our database. Please check your email.');
      setIsErrorMessage(false); // It's a normal message
    } catch (error) {
      console.error('Error:', error);
      setMessage('Error: ' + (error.response.data.message || error.message));
      setIsErrorMessage(true); // It's an error message
    }
  };

  return (
    <div className={styles.authContainer}>
      <h2>Forgot Password</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          className={styles.input}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />
        <button className={styles.button} type="submit">Send Reset Link</button>
      </form>
      {message && (
        <div className={`${styles.message} ${isErrorMessage ? styles.errorMessage : styles.normalMessage}`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;